<template>
  <div class="ma-2">
    <v-card-title> Settings </v-card-title>
    <v-container>
      <div class="checkbox-group">
        <v-checkbox
          style="margin-top: -1rem"
          @change="updateGreenhouseGas"
          v-model="profile.greenhouseGas"
          :color="profile.greenhouseGas ? 'primary' : ''"
        >
          <template v-slot:label>
            <span>
              <v-icon :color="profile.greenhouseGas ? 'primary' : ''"
                >mdi-molecule-co2</v-icon
              >
              Greenhouse Gas Calculations (future)
            </span>
            <!-- <v-tooltip max-width="400" bottom color="primary">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="primary">mdi-information</v-icon>
            </template>
            <span>
            </span>
          </v-tooltip> -->
          </template>
        </v-checkbox>

        <v-checkbox
          style="margin-top: -1rem; margin-right: 20px"
          @change="updateShowScreenSize"
          v-model="profile.showScreenSize"
          :color="profile.showScreenSize ? 'primary' : ''"
        >
          <template v-slot:label>
            <span>
              <v-icon :color="profile.showScreenSize ? 'primary' : ''"
                >mdi-monitor</v-icon
              >
              Show screen size icon
            </span>
          </template>
        </v-checkbox>
        <v-checkbox
          style="margin-top: -1rem; margin-right: 20px"
          @change="updateTeams"
          v-model="profile.enableTeams"
          :color="profile.greenhouseGas ? 'primary' : ''"
        >
          <template v-slot:label>
            <span>
              <v-icon :color="profile.enableTeams ? 'primary' : ''"
                >mdi-account-group</v-icon
              >
              Teams
            </span>
            <v-tooltip max-width="400" bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="primary">mdi-information</v-icon>
              </template>
              <span
                >Teams is designed for and can be enabled for Peeps accounts
                with many properties that are managed in teams. In this way,
                team members can focus on selected properties assigned to the
                team.
              </span>
            </v-tooltip>
          </template>
        </v-checkbox>

        <v-checkbox
          style="margin-top: -1rem; margin-right: 20px"
          @change="updateParcelReference"
          v-model="profile.includeParcelReference"
          :color="profile.includeParcelReference ? 'primary' : ''"
        >
          <template v-slot:label>
            <span>
              Include Parcel Reference
            </span>
          </template>
        </v-checkbox>

        <v-checkbox
          style="margin-top: -1rem; margin-right: 20px"
          @change="updateNearbyParcels"
          v-model="profile.enableNearbyParcels"
          :color="profile.enableNearbyParcels ? 'primary' : ''"
        >
          <template v-slot:label>
            <span>
              Enable Nearby Parcels
            </span>
          </template>
        </v-checkbox>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  computed: {
    profile: {
      get() {
        return this.$store.getters.user.profile
      },
      set(data) {
        return data
      }
    }
  },
  methods: {
    updateTeams() {
      this.changeProfileData({ enableTeams: this.profile.enableTeams })
    },
    updateGreenhouseGas() {
      this.changeProfileData({ greenhouseGas: this.profile.greenhouseGas })
    },
    updateShowScreenSize() {
      this.changeProfileData({ showScreenSize: this.profile.showScreenSize })
    },
    updateParcelReference() {
      this.changeProfileData({
        includeParcelReference: this.profile.includeParcelReference
      })
    },
    updateNearbyParcels() {
      this.changeProfileData({
        enableNearbyParcels: this.profile.enableNearbyParcels
      })
    }
  }
}
</script>

<style></style>
